<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="600px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            class="mx-1"
            icon
            depressed
            :color="color"
            v-bind="attrs"
            v-on="{ ...dialog, ...tooltip }"
            dark
            ><v-icon>mdi-account-network</v-icon></v-btn
          >
        </template>
        <span>Create Relationship</span>
      </v-tooltip>
    </template>
    <template>
      <v-card>
        <v-toolbar color="primary" dark>Lead Relationship</v-toolbar>
        <v-card-text class="d-flex">
          <v-autocomplete
            class="mx-1 my-3"
            :label="`Select by ${getCoordinatorN}`"
            :items="workers"
            v-model="doctorUuid"
            item-text="fullname"
            item-value="uuid"
            style="max-width: 300px"
            prepend-inner-icon="mdi-account-search"
            clearable
            hide-details
            outlined
            dense
          ></v-autocomplete>
          <v-select
            class="mx-1 my-3"
            :disabled="doctorUuid == '' || doctorUuid == null"
            v-model="ocupationId"
            label="Occupations"
            :items="ocupations"
            item-text="name"
            item-value="id"
            :loading="loading"
            hide-details
            outlined
            dense
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="cancel()">Close</v-btn>
          <v-btn
            :disabled="doctorUuid == '' || doctorUuid == null || loadingAction"
            :loading="loadingAction"
            color="primary"
            text
            @click="confirm()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError, notifySuccess } from "@/components/Notification";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default Vue.extend({
  props: {
    leadUuid: String,
    leadUuids: [],
    color: String,
  },
  name: "crm-asign-to",
  data() {
    return {
      dialog: false,
      doctorUuid: "",
      contador: 0,
      ocupationId: null,
      ocupations: [],
      loading: false,
      loadingAction: false,
    };
  },
  watch: {
    doctorUuid(val) {
      if (val != "" && val != null) {
        const worker = this.workers.filter((w) => w.uuid == val)[0];
        this.ocupationId = worker.ocupation.id;
      } else {
        this.ocupationId = null;
      }
    },
    contador(val) {
      if (val == this.leadUuids.length) {
        notifySuccess(this.$t("medicForm.notificationPatientTransfer"));
        this.cancel();
        this.$emit("assigned");
      }
    },
  },

  computed: {
    ...mapGetters(["getCoordinatorN"]),
    ...mapState("crmEmployeeModule", ["workers"]),
  },
  mounted() {
    if (this.workers.length == 0) {
      this.actGetEmployees();
    }
    this.listOcupations();
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmConfigurationsModule", [
      "actAsignToLead",
      "actMultiAsignToLead",
    ]),

    cancel() {
      this.dialog = false;
      this.doctorUuid = "";
      this.contador = 0;
      this.ocupationId = null;
      this.loadingAction = false;
    },

    async listOcupations() {
      this.ocupations = (await getAPI(`/sprocedures/listNom/Ocupation`)).data;
    },

    async confirm() {
      if (this.leadUuids == undefined || this.leadUuids.length == 0) {
        this.loadingAction = true;
        getAPI
          .post("/patient/createRelation", {
            leadUuid: this.leadUuid,
            employeeUuid: this.doctorUuid,
            ocupationId: this.ocupationId,
          })
          .then(() => {
            this.$emit("assigned");
            notifySuccess(this.$t("Realtionship has been created"));
            this.cancel();
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            this.loadingAction = false;

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else {
        const leadTransfer = this.leadUuids.map((lead) => lead.uuid);
        let body = {
          leadUuids: leadTransfer,
          create: true,
          employeeUuid: this.doctorUuid,
          ocupationId: this.ocupationId,
        };
        this.loadingAction = true;
        getAPI
          .post("/patient/createOrDeleteMultipleRelations", body)
          .then(() => {
            notifySuccess(this.$t("Realtionship has been created"));
            this.cancel();
            this.$emit("assigned");
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            this.loadingAction = false;

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },
  },
});
</script>
<style lang="scss" scope></style>
