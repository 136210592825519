<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card flat>
      <v-card-text>
        <!--         <div class="d-flex justify-end">
          <usae-pay-invoice />
        </div>
        <v-divider class="my-2"></v-divider> -->
        <template>
          <v-data-table
            :headers="headers"
            :items="quotes"
            class="elevation-1"
            item-key="id"
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ paymentDate(item.createdAt) }}
            </template>
            <template v-slot:[`item.description`]="{ item }">
              {{ getDescription(item) }}
            </template>
            <template v-slot:[`item.aproved`]="{ item }">
              <v-icon color="success" v-if="item.aproved">mdi-check-all</v-icon>
              <span class="orange--text" v-else><strong>SENT</strong> </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                {{/* View Quote PDF */}}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="getPdf(item.id)"
                    >
                      <v-icon>mdi-file-pdf</v-icon>
                    </v-btn>
                  </template>
                  <span>View Quote</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </template>
        <document-viewer
          v-if="showDocuments"
          v-model="showDocuments"
          :documents="quotepdf"
        />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
//import UsaePayInvoice from "@/views/Patients/components/UsaePayInvoice.vue";
import DocumentViewer from "@/views/PdfSign/DocumentViewer.vue";
import { apiBaseUrl } from "@/enviorment";
export default {
  components: { DocumentViewer },
  name: "lead-quotes",
  props: {
    quotesProps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDocuments: false,
      quotepdf: [],
      headers: [
        { text: "Id", value: "id", sortable: false },
        { text: "Type", value: "quote_type", sortable: false },
        { text: "Description", value: "description", sortable: false },
        {
          text: "Amount",
          value: "quotedAmount",
          sortable: false,
          align: "end",
        },
        { text: "Discount", value: "discount", sortable: false, align: "end" },
        { text: "Status", value: "aproved", sortable: false, align: "center" },
        { text: "Create At", value: "createdAt", sortable: false },
        { text: "", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
  computed: {
    quotes() {
      return this.quotesProps.length != 0 ? this.quotesProps : [];
    },
  },
  methods: {
    paymentDate(date) {
      return new Date(date).toISOString().slice(0, 10);
    },
    getDescription(item) {
      if (item.chargesList && item.chargesList.length != 0) {
        return item.chargesList[0].description;
      }
      return "-";
    },
    async getPdf(id) {
      const pdf = {
        title: "Quote PDF",
        url: `${apiBaseUrl}/sprocedures/getQuotePdf/${id}`,
      };

      this.quotepdf[0] = pdf;

      this.showDocuments = true;
    },
  },
};
</script>
<style lang="scss" scoped></style>
